export const environment = {
  production: true,
  baseHref: '/',
  domain: 'https://fma.dev.techland.link/',
  portalFmarket: 'https://fma.dev.techland.link/trade/',
  urlBackEnd : "https://api.fma.dev.techland.link/web",
  filesFmarket: 'https://files.fmarket.vn/dev/blog/',
  application:
  {
    name: 'angular-bootstrap',
    version: 'Angular 17.0.8',
    bootstrap: 'Bootstrap 5.3.2',
    fontawesome: 'Font Awesome 6.5.1',
  }
};